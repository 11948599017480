<template>
    <div v-if='!$store.state.loading'>
        <v-row>
            <v-col md=8>



            </v-col>
            <v-col md=4>
                <v-card>
                    <v-card-title>
                        <h1 class='title'>Atividade</h1>
                    </v-card-title>
                    <v-timeline dense>
            <v-timeline-item
              v-for="event in activity"
              v-bind:key="event.refcode"
              :color="dictionary[event.value] && dictionary[event.value].color ? dictionary[event.value].color : 'blue'"
              :icon="dictionary[event.value] && dictionary[event.value].icon ? dictionary[event.value].icon : ''"
            >
                            <p>
                                {{event.name}} >

                                {{dictionary[event.value] ? dictionary[event.value].name : event.value}}                              <!--    
                                <a :href="event.description && event.description.length > 0 ? '/#/' + event.description[0] : ''"><v-icon>mdi-eye</v-icon></a> -->
                                <br><small>{{event.time}}</small>
                            </p>
                        </v-timeline-item>
                    </v-timeline>        
                </v-card>
            </v-col>
        </v-row>
    </div>    

</template>

<script>
  const {EventBus} = require('../../addons/event-bus.js')

      // import StatusTableDisplay from '../../components/StatusTableDisplay.vue'
    export default {
        components: {
            // StatusTableDisplay
        },
        name: 'Dashboard',
        data() {
            return {

            }
        },
        methods: {
            getColor(event) {
                return this.dictionary[event.value] && this.dictionary[event.value].color ? this.dictionary[event.value].color : 'blue'
            },
            loadActivity() {
                this.$store.dispatch('getActivity').then(r => {
                    this.$store.commit('setReady')
                    return r
                })
            }
        },
        computed: {
            dictionary: function() {
                return this.$store.getters.dictionary
            },
            activity: function () {
                return this.$store.getters.activity
            }
        },
        mounted() {
            EventBus.$on('definition-loaded', () => {console.log('loaded')})
            this.loadActivity()
        },
        beforeDestroy() {
            EventBus.$off('definition-loaded')
        }
    }
</script>

<style>
    .dash-dict {}
</style>